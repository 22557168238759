<template>
	<div class="page-account">		
		<div v-if='!hasOem&&firstcont'>
			<Layout>
				<Header class="title">
					<div style="width:50px;display:flex;align-items:center;"><img style="width:100%;" src="@/assets/images/logo.png" alt=""></div>
					<div style="margin-left:10px">速速云签</div>
				</Header>
				<Layout>
					<Content>
						<div class="topHead">
							<span>签合同 ，都选速速云签</span>
							<div style="width:100%;margin-left:22%" class="name">—— 专业的电子合同SAAS服务平台</div>
						</div>
						<div class="introduce">
							<div class="content">
								<div class="contentTitle">速速云签 行业领先</div>
								<div class="contentVal">我们不仅是电子合同的代名词，更是国内知名电子合同PAAS软件服务提供商技术团队拥有超过20年的软件实践与创新经验。</div>
								<div class="con">
									<img src="@/assets/images/01.jpg">
								</div>
								<div class="con">
									<div class="conTitle">互联造物（hulianzaowu.com）致力于帮助传统企业提供数字化解决方案。</div>
									<div class="conCon">
										2002年开始不断从事定制开发，产品研发，打磨团队....2017年开始切入SAAS领域进行上线全国推广； 历经2年成功为2万客户实现数字化转型；遍布全国23个省，5个自治区，4个直辖市 其中80%为中小企业；截至2019年累计研发投入近千万，于2020年多次收获来自腾讯、科大讯飞等众多奖项。互联造物拥有全面的云产品组合，100多款面向所有业务线的解决方案和商务套件。如果您有更多需求，欢迎直接来电0551-63830830。
									</div>
								</div>
							</div>
						</div>
						<div class="introduce_b">
							<div class="contentdi">
								<div class="ent">
									<div class="entImg">
										<img src="@/assets/images/2.svg">
									</div>
									<div class="link">
										<div class="linkTit">《互联造物.电商小程序》</div>
										<div class="linkCon">代码开源、支持二开、定制、扩展 ，及免费的新版本升级，让企业获得即时优势和行业前沿洞见，助力提高利润、加速发展、进军新市场并颠覆行业格局。</div>
									</div>
								</div>
								<div class="ent">
									<div class="entImg">
										<img src="@/assets/images/3.svg">
									</div>
									<div class="link">
										<div class="linkTit">《互联造物.020系统》</div>
										<div class="linkCon">本地生活外卖跑腿平台 020系统，客户端、骑手端、商家端、员工端、总管理，打造一套完整的020闭环商业系统。</div>
									</div>
								</div>
								<div class="ent">
									<div class="entImg">
										<img src="@/assets/images/4.svg">
									</div>
									<div class="link">
										<div class="linkTit">《互联造物.智慧社区》</div>
										<div class="linkCon">社区一体化数字管理解决方案助力物业推进个性化进程，把握市场界限模糊所带来的机遇。通过数字化转型打造战略性发展，为跨行业奠定基础，最终实现一体化”的数字化智慧社区。</div>
									</div>
								</div>
								<div style="margin:0 24px" class="ent">
									<div class="entImg">
										<img src="@/assets/images/0003.png">
									</div>
									<div class="link">
										<div class="linkTit">《互联造物.电子合同》</div>
										<div class="linkCon">全终端电子合同签署、电子合同批量上传、发起及签署，电子合同在线编辑数据全程可控，身份安全认证、电子合同智能管理</div>
									</div>
								</div>
							</div>
						</div>
						<div class="registered">
							<div class="hasRegistered">已经注册？</div>
							<div id="loginBtn" class="loginBtn" @click="openModel">登录</div>
							<div class="hasRegistered toRegistered">立即注册</div>
							<div class="registeredForm">
								<fieldset>
									<legend class="area">地区</legend>
									<div data-toggle="distpicker">
										<div class="form-group">
											<select class="form-control" @change="getProvince" v-model="province">
												<option v-for="(item,index) in addresData" :key="index" :value="index">{{item.label}}</option>
											</select>
										</div>
										<div class="form-group">
											<select class="form-control" @change="getCity" v-model="city">
												<option v-for="(item,index) in addresData[province].children" :key="index" :value="index">{{item.label}}</option>
											</select>
										</div>
										<div class="form-group">
											<select class="form-control" @change="getArea" v-model="area">
												<option v-for="(item,index) in addresData[province].children[city].children" :key="index" :value="index">{{item.label}}</option>
											</select>
										</div>
									</div>
								</fieldset>
								<fieldset :class="focus == '2'?'fie':''">
									<legend class="lastName" v-if="focus == '2'" :class="focus == '2'?'fieColor':''">姓名</legend>
									<Input id="lastName" v-model="register.name" class="formRegistered" type="text" placeholder="姓名*" @on-focus="onfocus('2')" @on-blur="focus='0'"/>
								</fieldset>

								<fieldset :class="focus == '3'?'fie':''">
									<legend class="email" v-if="focus == '3'" :class="focus == '3'?'fieColor':''">电子邮件</legend>
									<Input id="email" v-model="register.email" class="formRegistered" type="text" placeholder="电子邮件*" @on-focus="onfocus('3')" @on-blur="focus='0'"/>
								</fieldset>

								<fieldset :class="focus == '4'?'fie':''">
									<legend class="company" v-if="focus == '4'" :class="focus == '4'?'fieColor':''">公司</legend>
									<Input id="company" v-model="register.company" class="formRegistered" type="text" placeholder="公司*" @on-focus="onfocus('4')" @on-blur="focus='0'"/>
								</fieldset>

								<fieldset :class="focus == '5'?'fie':''">
									<legend class="phone" v-if="focus == '5'" :class="focus == '5'?'fieColor':''">电话</legend>
									<Input id="phone" v-model="register.phone" class="formRegistered" type="text" placeholder="电话*" @on-focus="onfocus('5')" @on-blur="focus='0'"/>
								</fieldset>

								<fieldset>
									<legend class="relationship">与互联造物的关系</legend>
									<select id="relationship" v-model="register.relationship"  class="selectpicker">
										<option role="option" selected="selected" style="display: none"></option>
										<option value="REL10" role="option">互联造物员工</option>
										<option value="REL6" role="option">顾问</option>
										<option value="REL4" role="option">合作伙伴</option>
										<option value="REL2" role="option">客户</option>
										<option value="REL7" role="option">媒体/分析师</option>
										<option value="REL5" role="option">潜在合作伙伴</option>
										<option value="REL3" role="option">潜在客户</option>
										<option value="REL9" role="option">实习生</option>
										<option value="REL8" role="option">投资者/股东</option>
									</select>
								</fieldset>
								<div class="loginBtn" @click="registerSub">提交</div>
							</div>
						</div>
					</Content>
				</Layout>
				<Footer>
					<div>安徽互联造物云计算有限公司 &nbsp; © 2018-2021</div>
					<div class="dibu "><img src="@/assets/images/ba.png" alt="">&nbsp; 皖ICP备19001043号</div>
				</Footer>
			</Layout>
			<div class="main" v-if="showModel">
				<div class="loginHome">
					<div class="loginTop">
						<span>感谢您对互联造物的关注</span>
						<img id="close" src="@/assets/images/close.png" @click="closeModle">
					</div>
					<div class="loginTit">
						一次注册,永久访问
					</div>
					<div class="loginBody">
						<div class="login-group">
							<Form ref="formInline" :model="formInline" :rules="ruleInline"
									@keyup.enter="handleSubmit('formInline')">
								<FormItem prop="username">
								<Input type="text" v-model="formInline.username" prefix="ios-contact-outline"
										placeholder="请输入用户名" size="large"/>
								</FormItem>
								<FormItem prop="password">
								<Input type="password" v-model="formInline.password" prefix="ios-lock-outline"
										placeholder="请输入密码" size="large"/>
								</FormItem>
								<FormItem>
								<Button class="submitBtn" @click="handleSubmit('formInline')">登录</Button>
								</FormItem>
							</Form>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if='hasOem'>
			<div :style="'height:'+ innerHeight + 'px;'">
				<div :style="{'background': background,'background-size': '100% 100%'}" class="bg"></div>
				<div class="e">
					<Card style="width:500px;border: 1px solid #eeeeee; " shadow bordered :title="pageInfo.site_title || '速速云签'">
						<div style="margin:20px 30px">
							<Form ref="formInline" :model="formInline" :rules="ruleInline" @keyup.enter="handleSubmit('formInline')">
								<FormItem prop="username">
									<Input type="text" v-model="formInline.username" placeholder="请输入账户">
										<Icon size="18" type="ios-person-outline" slot="prepend"></Icon>
									</Input>
								</FormItem>
								<FormItem prop="password">
									<Input type="password" v-model="formInline.password" placeholder="请输入密码">
										<Icon size="18" type="ios-lock-outline" slot="prepend"></Icon>
									</Input>
								</FormItem>
								<FormItem>
									<Button type="primary" @click="handleSubmit('formInline')">登录</Button>
								</FormItem>
							</Form>
						</div>
					</Card>                    
				</div>
                <div class="footer_cla" v-if="omeData.oem_icp">
                    <div>{{ omeData.floor_cr }}  </div>
                    <div class="dibu zhizhen" @click="goIcpUrl()"><img src="@/assets/images/ba.png" alt="">&nbsp; {{ omeData.oem_icp }}</div>
                </div>
                
			</div>			
		</div>
		<Modal v-model="modals" scrollable footer-hide closable title="请完成安全校验" :mask-closable="false" :z-index="2"
			width="342">
			<div class="captchaBox">
				<div id="captcha" style="position: relative" ref="captcha"></div>
				<div id="msg"></div>
			</div>
		</Modal>
	</div>
</template>
<script>
import city from '@/utils/city'
import { AccountLogin,get_oem, } from '@/api/interFace'
import { registerInfo } from "../../api/register"
import {setCookies,removeCookies} from '@/utils/helper'
import '../../assets/js/canvas-nest.min'
export default {
	data () {
		return {
			focus: '0',
			province: '0',
			hasOem: false,
			pageInfo: {},//oem配置
			innerHeight: 0,			
			background_image: require("@/assets/images/login_bg2.jpg"),
			background: require("@/assets/images/login_bg2.jpg"),
			city: '0',
			area: '0',
			addresData: city,
            omeData: {
                floor_cr: '',
                oem_icp: '', //域名备案
                oem_icp_url: '', //域名备案地址
            },
			firstcont:false,
			showModel: false,
			modals: false,
			fullWidth: document.documentElement.clientWidth,
			swiperOption: {
				pagination: '.swiper-pagination',
				autoplay: true
			},
			autoLogin: true,
			formInline: {
				username: '',
				password: ''
			},
			ruleInline: {
				username: [
					{required: true, message: '请输入用户名', trigger: 'blur'}
				],
				password: [
					{required: true, message: '请输入密码', trigger: 'blur'}
				]
			},
			register: {
				province: '北京',
				city: '东城区',
				area: '',
				name: '',
				phone: '',
				email: '',
				company: '',
				relationship: '',
				type: 2,
				domain_url: window.location.href,
			}
		}
	},
	metaInfo: {
		title:'首页',
		meta: [
			{ name: 'viewport', content: 'width=device-width,user-scalable=yes,initial-scale=0.3,minimum-scale=0.3,maximum-scale=2' }
		]
	},
	created () {
		console.log('login1')
		var _this = this
		this.get_oem()
		top !== window && (top.location.href = location.href)  
		document.onkeydown = function () {
			if (_this.$route.name === '登录') {
				let key = window.event.keyCode
				if (key === 13) {
					_this.handleSubmit('formInline')
				}
			}
		}
		window.addEventListener('resize', this.handleResize)
	},
	watch: {
		fullWidth (val) {
			// 为了避免频繁触发resize函数导致页面卡顿，使用定时器
			if (!this.timer) {
				// 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
				this.screenWidth = val
				this.timer = true
				let that = this
				setTimeout(function () {
				// 打印screenWidth变化的值
					that.timer = false
				}, 400)
			}
		}
	},
	mounted: function () {
	},
	beforeCreate() {
		// window.document.title = "【速速云签】电子合同、电子签名、电子签章、印章管理、数据存证paap系统服务平台"
	},
	methods: {
        goIcpUrl(){
            window.open(this.omeData.oem_icp_url||'https://beian.miit.gov.cn/')
        },
		get_oem(){
			get_oem().then( res =>{
				console.log('获取oem',res)
				if(res.data.login_bg){
					this.pageInfo = res.data					
					this.background = 'url(' + res.data.login_bg + ') no-repeat' || require("@/assets/images/login_bg2.jpg");
					window.document.title = res.data.site_title || "【速速云签】电子合同、电子签名、电子签章、印章管理、数据存证paap系统服务平台"
					this.hasOem = true
				}else{
					this.hasOem = false
					window.document.title = "【速速云签】电子合同、电子签名、电子签章、印章管理、数据存证paap系统服务平台"
					this.firstcont = true
				}
                if(res.data.icp){                    
					// localStorage.setItem("oem_icp",res.data.icp) //ome域名备案
                    this.omeData.oem_icp = res.data.icp
                    this.omeData.floor_cr = res.data.floor_cr
                }
                
                if(res.data.icp_url){
					// localStorage.setItem("oem_icp_url",res.data.icp_url) //ome域名备案 跳转链接
                    this.omeData.oem_icp_url = res.data.icp_url
                }
				if(res.data.site_logo){
                    //修改登录页LOGO
                    let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                    link.type = 'image/x-icon';
                    link.rel = 'shortcut icon';
                    link.href = this.pageInfo.site_logo
                    document.getElementsByTagName('head')[0].appendChild(link);
                }else{
                    let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                    link.type = 'image/x-icon';
                    link.rel = 'shortcut icon';
                    link.href ='favicon.ico'
                    document.getElementsByTagName('head')[0].appendChild(link);
                }
			}).catch(res=>{
				this.hasOem = false
				// this.$Message.error(res.msg)
				window.document.title = "【速速云签】电子合同、电子签名、电子签章、印章管理、数据存证paap系统服务平台"
				console.log('res',res)
                this.firstcont = true
			})
		},
		/**修改高度 */
		restHeight() {
			this.innerHeight = window.innerHeight - 2;
		},
		registerSub(){
			registerInfo(this.register).then(res=>{
				this.$Message.success(res.msg)
				this.reset()
			}).catch(res=>{
				this.$Message.error(res.msg)
			})
		},
		onfocus  (that) {
			this.focus = that;
		},
		reset(){
			this.register.province = '北京'
			this.register.city = '东城区'
			this.register.area = ''
			this.register.name = ''
			this.register.phone = ''
			this.register.email = ''
			this.register.company = ''
			this.register.relationship = ''
			this.province= '0'
			this.city= '0'
			this.area= '0'
		},
		
		// 关闭模态框
		closeModel () {
			let msg = this.$Message.loading({
				content: '登录中...',
				duration: 0
			})			
			AccountLogin({
				username: this.formInline.username,
				password: this.formInline.password,
				type: 1    //0  老版本     1  新版本
			}).then(res => {
				msg()
				let data = res.data
				if (data.userinfo.status == 0) {
					let test = window.location.href;    
					localStorage.setItem("InitializeUrl",test) //登录的网站链接
					let expires = this.getExpiresTime(data.expires_time)
					// 登陆信息
					setCookies('token', data.token, expires)
					setCookies('expires_time', data.expires_time, expires)
					setCookies('uuid', data.userinfo.id, expires)
					setCookies('version', data.version_status)
					this.$router.replace({ path: '/index' })
					this.$store.commit('setMenus', data.menus)
					this.$store.commit('setAuth', data.unique_auth)
					this.$store.commit('setUserInfo', data.userinfo)
					this.$store.commit('setVersion', data.version_status)
					this.$store.commit('setIdentity', data.identity) //唐+ 身份识别					
                    localStorage.setItem("is_sass_admin", data.is_sass_admin == 1 ? 1 : 0) //是否从sass 跳入 如果是 则用户管理下可以删除废弃合同s					
                    localStorage.setItem("is_template_sharing", data.is_template_sharing == 1 ? 1 : 0) //是否开启共享功能  若开启则模板列表有开关是否开启
					localStorage.setItem("pay_type_h5", data.pay_type ? data.pay_type : 0 ) //是否展示h5支付 pay_type 为2不显示H5支付
					//立刻签进入后标题刷新还有
					if(this.pageInfo.site_logo){
						localStorage.setItem(document.domain+'_companyInfo', JSON.stringify(this.pageInfo))
					}				
				} else {
					removeCookies('token')
					removeCookies('expires_time')
					this.$Message.error('您已被限制登录')
				}
			}).catch(res => {
				msg()
				let data = res === undefined ? {} : res
				this.$Message.error(data.msg || '登录失败')
			})
		},
		openModel () {
			this.showModel = true
		},
		closeModle () {
			this.showModel = false
		},
		getProvince () {
			this.register.province = city[this.province].label
			this.register.city = city[this.province].children[this.city].label
			if (city[this.province].children[this.city].children) {
				this.register.area = city[this.province].children[this.city].children[this.area].label
			}else{
				this.register.area = ''
			}
			this.city = '0';
			this.area = '0';
		},
		getCity () {
			this.register.city = city[this.province].children[this.city].label
			this.register.area = city[this.province].children[this.city].children[this.area].label
			this.area = '0';
		},
		getArea(){
			this.register.area = city[this.province].children[this.city].children[this.area].label
		},
		getExpiresTime (expiresTime) {
			let nowTimeNum = Math.round(new Date() / 1000)
			let expiresTimeNum = expiresTime - nowTimeNum
			return parseFloat(parseFloat(parseFloat(expiresTimeNum / 60) / 60) / 24)
		},
		handleSubmit (name) {
			this.$refs[name].validate((valid) => {
				if (valid) {
				this.closeModel()
				}
			})
		}
	},
}
</script>
<style scoped>
/* oem配置 */
 .ivu-icon {
    width: 30px
  }
  .e {
    /* width: 100%; */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 65%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 999;
  }
  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;    
  }
  /deep/ .ivu-card-head {
    text-align: center;
  }
  /deep/ .ivu-card-head p, .ivu-card-head-inner {
    height: 35px !important;
    line-height: 35px !important;
    font-size: 30px !important;
  }



.fieColor {
	color : #1A73E8;
}
.fie {
	border:1px solid  #1A73E8;
}
.code {
	display: flex;
	align-items: center;
	justify-content: center;
}

.code .pictrue {
    height: 40px;
}
.selectpicker {
	width: 100%;
	border: none;
	outline: none;
}
input[type=text]:focus{
	outline: none;
	border: 1px solid #fff;
}
.loginTop span {
	float: left;
}
.m-b {
	margin-bottom: 15px;
}
.input-group {
	position: relative;
	display: table;
	border-collapse: separate;
}
.form-group {
	margin-bottom: 15px;
}
.loginBody {
	width: 100%;
	background: #fff;
}
.login-group {
	margin-top: 50px;
	padding: 0 60px 60px;
	color: #111123;
}
.footer_cla {
    position: fixed;
    bottom: 10px;
    z-index: 9999;
    text-align: center;
    width: 100%;
    font-size:14px;
}
.main {
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,.75);
	position: absolute;
	z-index: 1000;
	top: 0;
	left: 0;
	color: #FFF;
	text-align: center;
	height:100vh
}
.loginHome {
	width: 600px;
	background: #ffffff;
	display: inline-block;
	margin-top: 100px;
}
.loginTop img {
	width: 20px;
	margin-top: 15px;
}
.loginTit {
	width: 100%;
	height: 100px;
	line-height: 100px;
	background: #008FD3;
	font-size: 30px;
}
.loginTop {
	width: 100%;
	height: 50px;
	line-height: 50px;
	background: #000;
	text-align: right;
	font-size: 14px;
	padding: 0 10px;
}
.page-account {
	width: 100%;
	height: 100vh;
	overflow: auto;
	overflow-x: hidden;
}
.title {
	display:flex;
	height:64px;
	width:100%;
	color: #ffffff;
	font-size: 30px;
	font-weight: bold;
}
.ivu-layout-header,.ivu-layout-footer {
	background-color : #000000;
}
.ivu-layout-header {
	padding: 0 190px;
}
.ivu-layout-footer {
	color:#ffffff;
	text-align :center;
}
.topHead {
	height:340px;
	width:100%;
	background-image: linear-gradient(to right, #222222 , #545454);
	padding-top: 100px;
	padding-left: 190px;
	font-size: 45px;
	color: #ffffff;
}
.name {
	color: #F0AB00;
	margin-left:10px;
}
.introduce {
	width: 100%;
	height: auto;
	background: #F6F6F6;
	padding: 100px 0px;
}
.content {
	width: 50%;
	margin: 0px 40% 0px 10%;
	min-width: 960px;
	display: flow-root;
}
.contentTitle {
	color: #000000;
	font-size: 40px;
	text-align: center;
	width: 60%;
	margin: 0px 20%;
	min-width: 580px;
}
.contentVal {
	color: #000000;
	font-size: 20px;
	text-align: center;
	width: 60%;
	margin: 10px 20%;
	min-width: 580px;
}
.con {
	width: 50%;
	float: left;
	padding: 30px 0;
}
.con img {
	width: 80%;
	margin: 0px 10%;
}
.conTitle {
	font-size: 20px;
}
.conCon {
	line-height: 25px;
	margin-top: 15px;
	font-size: 15px;
}
.introduce_b {
	width: 100%;
	height: auto;
	padding: 0px;
	background: #ffffff;
}
.contentdi {
	width: 100%;
	padding: 0px 0px 0 5%;
	min-width: 960px;
	display: flex;
	box-sizing:border-box
}
.ent {
	width: 30%;
	float: left;
}
.entImg {
	width: 80%;
	margin: 20px 15% 0px;
}
.entImg img {
	width: 40%;
}
.link {
	width: 80%;
	margin: 0px 10% 40px 0px;
}
.linkTit {
	font-size: 18px;
}
.linkCon {
	margin-top: 10px;
	font-size: 14px;
}
.dibu{
	display:flex;
	align-items:center;
	justify-content:center
    
}
.zhizhen {
    cursor: pointer;
}
.registered {
	width: 350px;
	height: auto;
	background: #ffffff;
	position: absolute;
	top: 60px;
	right: 80px;
	padding: 40px;
}
.hasRegistered {
	color: #000000;
	font-size: 26px;
}
.loginBtn {
	color: #ffffff;
	background: #007db8;
	width: 80px;
	line-height: 40px;
	height: 40px;
	text-align: center;
	font-size: 18px;
	margin: 20px 0;
	cursor: pointer;
}
.hasRegistered {
	color: #000000;
	font-size: 26px;
}
.form-group {
	margin-bottom: 15px;
}
.form-control{
	background-color: #FFF;
	background-image: none;
	border: 1px solid #e5e6e7;
	border-radius: 1px;
	color: inherit;
	display: block;
	padding: 6px 12px;
	-webkit-transition: border-color .15s ease-in-out 0s,box-shadow .15s ease-in-out 0s;
	transition: border-color .15s ease-in-out 0s,box-shadow .15s ease-in-out 0s;
	width: 100%;
	font-size: 14px;
	height: 34px;
}
.submitBtn {
	width: 100%;
	color: #ffffff;
	background-color: #008FD3;
	height: 40px;
}
fieldset {
	min-width: 0;
	padding: 0.35em .625em 0.35em;
	margin: 0px;
	border: 1px solid silver;
	margin-bottom: 18px;
	display: block;
	margin-inline-start: 2px;
	margin-inline-end: 2px;
	padding-block-start: 0.35em;
	padding-inline-start: 0.75em;
	padding-inline-end: 0.75em;
	padding-block-end: 0.625em;
	min-inline-size: min-content;
}
legend {
	width: auto;
	border-bottom: 0px;
	font-size: 14px;
	margin-bottom: 0px;
	line-height: inherit;
	color: #333;
	border: 0;
	padding: 0;
	display: block;
}
.check {
	color: blue;
}
.checkReg {
	height: 28px;
}
.formRegistered {
	width: 100%;
	font-size: 14px;
	border: 0px;
	padding-left: 5px;
}
</style>
